<template>
  <v-form>
    <v-row align="center" justify="center" class="pa-4">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #e53935">จัดการบริษัท (EPP)</h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มบริษัท (EPP)</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersCompany"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.companyImg`]="{ item }">
              <v-img :src="item.companyImg" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="ViewCompany(item)">mdi-eye</v-icon>
                <v-icon @click="UpdateCompany(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteCompany(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      headersCompany: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รูปภาพโลโก้", value: "companyImg", align: "center" },
        { text: "รหัสบริษัท", value: "companyCode", align: "center" },
        { text: "ชื่อบริษัท", value: "companyName", align: "center" },
        { text: "Email บริษัท", value: "suffixEmail", align: "center" },
        { text: "รหัสเชิญชวน", value: "invitationCode", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
        // รหัสบริษัท
        // ชื่อบริษัท
        // Email บริษัท
        // รายละเอียด
        // รูปภาพโลโก้
        // // แบรนด์สินค้า
      ],
      items: [],
      search: "",
      count: 0,
      user: "",
    };
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("userSPM")));
    this.getAllcompany();
  },
  methods: {
    async getAllcompany() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllcompany", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateCompany(val) {
      localStorage.setItem("Companydata", Encode.encode(val));
      this.$router.push("EditCompany");
    },
    ViewCompany(val) {
      localStorage.setItem("Companydata", Encode.encode(val));
      this.$router.push("/ViewCompany");
    },
    async DeleteCompany(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/companies/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllcompany();
        }
      });
    },
    goToCreate() {
      this.$router.push("createcompany");
    },
  },
};
</script>